import React from "react";
import {graphql} from "gatsby";

import Layout from "/src/components/layouts/Layout";
import Header from "/src/components/pages/products/Header";
import KeyFeatures from "/src/components/pages/products/KeyFeatures";
import Benefits from "/src/components/pages/products/Benefits";
import Functions from "/src/components/pages/products/Functions";

import {LottieAnimation} from "../../components/styled/lotti/LottieAnimations";

import circuit2 from "/static/assets/animations/circuit/circuit_right01.json";
import RelatedContentSection from "../../components/pages/products/RelatedContentSection";

const OneIdentityActiveRoles = ({data}) => {

    const {active} = data

    return (
        <Layout lang={active.lang} seo={active.SEO}>
            <Header
                title_white={active.product.title_white}
                title_color={active.product.title_color}
                brand_color="one-identity-primary"
                subtitle={active.product.subtitle}
                description={active.product.description}
                url={active.product.url}
                url_download={active.product.url_download}
                src={active.product.product_icon.url}
            />
            <KeyFeatures
                big_title={active.key_features_title}
                cards={active.key_features_card}
            />
            <LottieAnimation animationData={circuit2} right="true"/>
            <Benefits
                big_title={active.benefits_title}
                benefits={active.benefits_card}
                button={active.benefits_button}
                button_download={active.benefits_download_button}
                button_style="one-identity-primary"
            />
            <Functions
                big_title={active.features_title}
                cards={active.features_card}
                button={active.features_button}
                button_color="one-identity-primary"
            />
            <RelatedContentSection
                locale={active.lang}
                related1_slug={active.related_content.related1_slug}
                related2_slug={active.related_content.related2_slug}
                related3_slug={active.related_content.related3_slug}
                related4_slug={active.related_content.related4_slug}
            />
        </Layout>
    )
}

export const query = graphql`
   query GetSingleActiveRoles($locale: String) {
         active: strapiOneIdentityActiveRoles(lang: { eq: $locale }) {
          lang
          product {
            title_white
            title_color
            subtitle
            description
            product_icon {
              url
            }
            url {
              link
              name
            }
            url_download {
              file {
                url
              }
              name
            }
          }
          key_features_title
          key_features_card {
            avatar {
              url
            }
            title
            description
            list {
              id
              item
            }
            id
          }
          benefits_title
          benefits_card {
            id
            title
          }
          benefits_button {
            link
            name
          }
          benefits_download_button {
            file {
              url
            }
            name
          }
          features_title
          features_card {
            id
            title
            description
            avatar {
              url
            }
            List {
              id
              item
            }
          }
          features_button {
            id
            link
            name
          }
          related_content {
                related1_slug
                related2_slug
                related3_slug
          }
          SEO {
            title
            isIndexable
            description
            keywords
            preview {
              url
            }
          }
      }
   }
 `

export default OneIdentityActiveRoles